export default async function logError(message) {
  console.log(`Logging Error ${message}`);
  try {
    await fetch(process.env.VUE_APP_WEB_ERROR_SLACK_BOT_URL, {
      method: "POST",
      body: JSON.stringify({
        type: "mrkdwn",
        text: message,
      }),
    });
  } catch (e) {
    await fetch(process.env.VUE_APP_WEB_ERROR_SLACK_BOT_URL, {
      method: "POST",
      body: JSON.stringify({
        type: "mrkdwn",
        text: e.toString(),
      }),
    });
  }
}

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import { AuthService } from "@/revamp/firebase/config";
import i18n from "./i18n";
require("@/revamp/assets/main.scss");

const vuetify = createVuetify({
  components,
  directives,
});

let app;

AuthService.onAuthStateChanged(() => {
  // i18n, render: h => h(App),
  console.log("Auth State Changed");
  if (!app) {
    // app.use(i18n);
    app = createApp(App);
    app.use(i18n);
    app.use(router);
    app.use(vuetify);
    app.mount("#app");
  }
});

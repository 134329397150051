<template>
  <div class="content">
    <Background>
      <Loading v-if="loading" isCenter isFull />
      <FriendlierCard v-else>
        <div class="padded-block">
          <div class="logo"><img :src="FriendlierPrimaryCloud" /></div>
          <span v-if="!success">
            <UserClaim
              v-if="!claimLoading"
              :submit="claim"
              :container="container"
            />
            <ContainerImpact v-if="!claimLoading" :containers="[container]" />
            <Loading v-if="claimLoading" isCenter />
          </span>
          <span v-else>
            <div class="is-cloud mt-4">
              Successful Claim! Check your email for more instructions, or read
              below
            </div>
          </span>
          <div class="error">{{ error }}</div>
        </div>
        <div
          class="image-spacer"
          :style="{ backgroundImage: 'url(' + FourContainerSushiFirst + ')' }"
        />

        <v-bottom-sheet
          v-model="sheetVisible"
          persistent
          class="custom-bottom-sheet"
        >
          <div class="sheet-content">
            <div>Choose where to make your return</div>
            <v-divider></v-divider>
            <v-btn
              class="text-none mb-4 mt-5"
              color="#032674"
              size="x-large"
              variant="flat"
              block
              @click="openApp"
            >
              Go to App
            </v-btn>

            <v-btn
              @click="sheetVisible = false"
              class="text-none"
              color="grey-lighten-3"
              size="x-large"
              variant="flat"
              block
            >
              Stay on Web
            </v-btn>
          </div>
        </v-bottom-sheet>

        <div class="padded-block">
          <div class="title is-center semi-bold">Next Steps</div>

          <div
            class="detail-card"
            v-for="detail in details"
            :key="detail.title"
          >
            <div>{{ detail.title }}</div>
            <div><img :src="detail.image" /></div>
            <p v-if="detail.title !== 'deposit'">{{ detail.text }}</p>
            <p v-else style="text-align: left">
              Once you receive a notification that your deposit has been
              processed, you can either:
              <br />
              1. Request a payout via e-transfer

              <br />2. Donate to a local charity Visit friendlier.com for a list
              of charities.
            </p>

            <span v-if="detail.buttonText" class="button">
              <a
                target="_blank"
                :href="detail.buttonLink"
                @click="detail.buttonClick"
                >{{ detail.buttonText }}</a
              >
            </span>

            <span class="divider" />
          </div>

          <div class="sign-off">Connect with us!</div>
          <div class="button-row">
            <div class="button big-button">
              <a href="https://friendlier.com" target="_blank"
                >friendlier.com</a
              >
            </div>
          </div>

          <div class="foot">
            <a
              href="https://www.friendlier.com/general-5"
              target="_blank"
              style="color: inherit; text-decoration: none"
            >
              <span>FAQ</span>
            </a>
            <span style="text-decoration: none">|</span>

            <a
              href="https://www.friendlier.com/privacy-policy"
              target="_blank"
              style="color: inherit; text-decoration: none"
            >
              <span>Privacy Policy</span>
            </a>
            <span style="text-decoration: none">|</span>

            <a
              href="https://www.friendlier.com/terms-of-service"
              target="_blank"
              style="color: inherit; text-decoration: none"
            >
              <span>Terms and Service</span>
            </a>
          </div>
          <div style="color: white; padding-top: 10px">
            © 2024 Friendlier. All rights reserved.
          </div>
        </div>
      </FriendlierCard>
    </Background>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";

import { FriendlierPrimaryCloud } from "@/revamp/assets/images";
import { FourContainerSushiFirst } from "@/revamp/assets/images";
import {
  LineDropOffBinOcean,
  LinePersonOcean,
  MoneyWithArrowOcean,
  LineEarthOcean,
  Account,
} from "@/revamp/assets/images";
import {
  ContainerImpact,
  UserClaim,
  Loading,
  Background,
  FriendlierCard,
} from "@/revamp/components";
import { notifyStore } from "@/revamp/store";
import { useFirebase } from "@/revamp/composables";
import { logError, request } from "@/revamp/utils";

const sheetVisible = ref(false);

const container = ref("");
const error = ref("");
const automatic = ref(false);

const loading = ref(true);
const claimLoading = ref(false);
const success = ref(false);

const APIService = useFirebase();
const Router = useRouter();
const route = useRoute();

const isDesktop = () => window.innerWidth >= 1024;

const openApp = () => {
  // const universalLink = `https://friendlier-mobile-dev.web.app/${route.query.code}/app`;
  const universalLink = `https://links.friendlier.com/${route.query.code}/app`;

  window.location.href = universalLink;
};

onMounted(async () => {
  // console.log(container.value);
  // sheetVisible.value = !isDesktop();

  const code = route.query.code;

  if (code) {
    const qrCodeData = await APIService.getQRCode(code);
    if (qrCodeData) {
      container.value = qrCodeData;
      console.log("Container set:", container.value);
    }
  }

  if (!container.value) {
    container.value = new URL(location.href).searchParams.get("id");
    automatic.value = !!container.value;
  }

  loading.value = false;
  window.history.replaceState({}, document.title, "/return");
});

const details = ref([
  {
    title: "account",
    image: Account,
    text: "Complete your Friendlier account to request your deposit. Please allow up to 2 weeks for your deposit to be processed and become available to claim.",
    buttonLink: process.env.VUE_APP_HOSTING_URL + "/completeSignup",
    buttonText: "Complete Account",
  },

  {
    title: "return",
    image: LineDropOffBinOcean,
    text: "Bring your reusable packaging to a participating drop off location.",
    buttonLink: "https://www.friendlier.com/locations",
    buttonText: "Locations",
  },
  // {
  //   title: "create",
  //   image: LinePersonOcean,
  //   text: "Create your Friendlier account to claim your deposit. You can do this by clicking below. As a note, please allow up to 2 weeks for your deposit to be processed and become avaliable to claim.",
  //   buttonText: "Complete Profile",
  //   buttonLink: process.env.VUE_APP_HOSTING_URL + "/completeSignup",
  // },
  {
    title: "deposit",
    image: MoneyWithArrowOcean,
    text: "Once you receive a notification that your deposit has been processed, you can either: 1. Request a payout via e-transfer 2. Donate to a local charity Visit friendlier.com for a list of charities.",
  },
  {
    title: "save",
    image: LineEarthOcean,
    text: "Thank you for being a part of the solution! A small switch to reusables makes a huge difference in the world.",
  },
]);

async function init() {
  loading.value = true;

  container.value = new URL(location.href).searchParams.get("id");

  // if (container.value != "") {
  //   try {
  //     let code = await request(
  //       `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/code`,
  //       "POST",
  //       {
  //         id: container.value,
  //         fields: ["distributed"],
  //       },
  //       "fastlane.return.init",
  //       false
  //     );
  //     if (code.distributed == "R100226") {
  //       Router.push(`${document.title}/RC-SHOW?code=${container.value}`);
  //     }
  //   } catch (e) {
  //     logError(`fastlane.return.init: failed to fetch code ${e.toString()}`);
  //   }
  // }

  if (container.value != "") {
    automatic.value = true;
  }
  window.history.replaceState({}, document.title, "/" + "return");
  loading.value = false;
}

async function claim(container, email) {
  claimLoading.value = true;
  if (container == "" || email == "") {
    error.value = "Please make sure a container code and email were entered";
    return;
  }
  error.value = "";
  let claimResponse = await APIService.claimCode(container, email, {
    source: automatic.value ? "automatic" : "manual",
    platform: "web",
    codeForm: automatic.value ? "dynamic-link" : "pure-code",
  });

  if (claimResponse == null || claimResponse == undefined) {
    error.value = "Failed to claim container";
  }

  if (claimResponse.error) {
    switch (claimResponse.code) {
      case 10000:
        // Malformed Code / Email show generic error
        error.value = "Please check the code and email.";
        break;
      case 10001:
        // Unknown Error - notify user
        unknownError();
        break;
      case 30000:
      case 30002:
      case 30003:
        //Code Not Found, failed to fetch code data, unrecognized container , show generic error
        error.value = "Failed to claim container, try again later";
        break;
      case 30004:
        // Already Claimed, notify user
        notifyStore.createModal(
          "Notice",
          "This container has already been claimed. Please place it in a return bin.",
          [
            {
              id: "close",
              text: "Dismiss",
              action: "close",
            },
          ],
          true
        );
        break;
      case 20005:
        // Unknown Error - notify user
        logError(
          `Return.Claim: Unable to find user during claim. ${container} ${email}`
        );
        unknownError();
        break;
      case 20004:
        if (claimResponse.generatedUser) unknownLoginNotify();
        else knownLoginNotify();
        break;
      default:
        unknownError();
        break;
    }
  }

  let emailResponse = null;
  if (claimResponse.didClaim && claimResponse.shouldSend) {
    emailResponse = await APIService.sendSignupEmail(email);
  }

  if (emailResponse && emailResponse.error) {
    switch (emailResponse.code) {
      case 10000:
        logError(`Return.Claim: Didn't send the required fields. ${email}`);
        break;
      case 10001:
        logError(`Return.Claim: Server error. ${email}`);
        break;
      case 20000:
        logError(`Return.Claim: Failed to create dummy user. ${email}`);
        break;
      case 20004:
        logError(
          `Return.Claim: Failed to create signup link for user. ${email}`
        );
        break;
      default:
        break;
    }
  }

  if (emailResponse && "link" in emailResponse) {
    details.value[1].buttonLink = emailResponse["link"];
  }

  if (!claimResponse.error) {
    success.value = true;
  }
  claimLoading.value = false;
}

const unknownError = () => {
  notifyStore.createModal(
    "Error",
    "Something has gone wrong, please try again later or reach out to Friendlier for help",
    [
      {
        id: "close",
        text: "Dismiss",
        action: "close",
      },
    ],
    true
  );
};

const knownLoginNotify = () => {
  notifyStore.createModal(
    "Alert",
    "You have used all of your allowed anonymous container claims. Please login to claim more containers",
    [
      {
        id: "login",
        text: "Login",
        callback: () => {
          Router.push("/");
        },
        action: "close",
      },
      {
        id: "close",
        text: "Dismiss",
        action: "close",
      },
    ],
    true
  );
};

const unknownLoginNotify = () => {
  notifyStore.createModal(
    "Alert",
    "You have used all of your allowed anonymous container claims. Please finish your registration to claim more.",
    [
      {
        id: "signup",
        text: "Finish Registration",
        callback: () => {
          Router.push("/completeSignup");
        },
        action: "close",
      },
      {
        id: "close",
        text: "Dismiss",
        action: "close",
      },
    ],
    true
  );
};
</script>

<style scoped lang="scss">
@import "@/revamp/assets/scss/colors.scss";
@import "@/revamp/assets/scss/elements.scss";
@import "@/revamp/assets/scss/variables.scss";

.sheet-content {
  padding: 1em;
  border-radius: 15px 15px 0px 0px;
  height: 32vh !important;
  font-size: large;
  font-weight: 800;
  background-color: white;
  text-align: center;
  height: 100%;
}

.logo {
  max-width: 30%;
  min-width: 100%;
}

.error {
  color: red;
  width: 100%;
  margin-top: 15px;
}

.image-spacer {
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.title {
  color: $sunflower;
}

.detail-card {
  margin-top: 10px;
  div {
    font-size: 2rem;
    color: $cloud;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  img {
    max-height: 125px;
    max-width: 150px;
    margin: 5px auto;
    text-align: center;
  }
  p {
    color: $cloud;
    margin: 0px 30px !important;
    margin-bottom: 20px !important;
    text-align: center;
  }
  span.divider {
    background-color: $cloud;
    height: 1px;
    width: 80%;
    display: block;
    margin: 0 10%;
    margin-top: 20px;
  }
}

.sign-off {
  color: $cloud;
  margin: 0px 30px;
  text-align: center;
  margin-top: 20px;
}

.button-row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  .big-button {
    background-color: $cloud;
    color: $denim;
    text-align: center;
  }
}

.foot {
  display: flex;
  justify-content: space-between;

  span {
    text-decoration: underline;
    color: $cloud;
  }
}
</style>

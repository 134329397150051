import { createRouter, createWebHistory } from "vue-router";
import { AuthService } from "@/revamp/firebase/config";
import { UserUpdate } from "@/components";
import { Dashboard } from "@/views";
import {
  Return,
  Welcome,
  FourOhFour,
  VerifyEmail,
  CompleteSignup,
  RCShow,
  OutletScan,
  RCShowDetails,
  updateEmail,
} from "@/revamp/views";

const authRoutes = (to, from, next) => {
  let user = AuthService.currentUser;
  if (!user) {
    next({ name: "Welcome" });
  } else {
    next();
  }
};

const noAuth = (to, from, next) => {
  let user = AuthService.currentUser;
  if (user) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
    beforeEnter: noAuth,
  },
  {
    path: "/return",
    name: "Return",
    component: Return,
    beforeEnter: noAuth,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: authRoutes,
  },
  {
    path: "/verifyEmail",
    name: "VerifyEmail",
    component: VerifyEmail,
  },
  {
    path: "/completeSignup",
    name: "CompleteSignup",
    component: CompleteSignup,
  },
  {
    path: "/change_password",
    name: "ChangePassword",
    component: UserUpdate,
  },
  {
    path: "/change_email_request",
    name: "ChangeEmailRequest",
    component: UserUpdate,
  },
  {
    path: "/updateEmail",
    name: "UpdateEmail",
    component: updateEmail,
  },
  {
    path: "/RC-SHOW-DETAILS",
    name: "RC Show Details",
    component: RCShowDetails,
  },
  {
    path: "/:code([a-zA-Z0-9]{5})",
    redirect: (to) => ({ name: "Return", query: { code: to.params.code } }),
  },
  {
    path: "/quickscan/:banner([a-zA-Z]+)/:store(R[0-9]{6})",
    name: "Farmboy Quick Scan",
    component: OutletScan,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "FourOhFour",
    component: FourOhFour,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
